var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('BlurredTenant',{attrs:{"showBlur":_vm.showBlur}},[_c('x-default-data-table',{attrs:{"x-options":_vm.tableOptions,"x-context":_vm.self,"x-container":_vm.tableContainer},scopedSlots:_vm._u([{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticStyle:{"padding":"0px !important"},attrs:{"colspan":headers.length}},[_c('div',{staticClass:"px-4 py-4 grey lighten-3"},[_c('connectors',{attrs:{"item":item}})],1)])]}},{key:"item.detail",fn:function(ref){
var item = ref.item;
return [(item.lastMessageConnector)?_c('div',{staticClass:"two-line-text detail-error-station"},_vm._l((item.lastMessageConnector),function(value,key){return _c('div',{key:key + value.timestamp},[_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({},'span',attrs,false),on),[_vm._v(_vm._s(value.message))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(value.message))])])],1)}),0):_vm._e()]}},{key:"item.connectors",fn:function(ref){
var item = ref.item;
return [(item.details.connectors)?_c('div',{staticStyle:{"max-width":"200px"}},_vm._l((item.details.connectors),function(connector){return _c('v-chip',{key:connector.connectorId,staticClass:"mr-2 my-1",attrs:{"color":(['Available'].includes(connector.status) && 'success') ||
            (['Unavailable', 'Offline', 'Faulted'].includes(connector.status) &&
                'error') ||
            'info'}},[_c('v-avatar',{staticClass:"white black--text",attrs:{"left":""}},[_vm._v(" "+_vm._s(connector.connectorId)+" ")]),_vm._v(" "+_vm._s(connector.status)+" ")],1)}),1):_vm._e()]}},{key:"item.errors",fn:function(ref){
            var item = ref.item;
return [(item.details.connectors)?_c('div',{staticStyle:{"max-width":"200px"}},_vm._l((item.details.connectors),function(connector){return _c('v-chip',{key:connector.connectorId,staticClass:"mr-2 my-1"},[_c('v-avatar',{staticClass:"white black--text",attrs:{"left":""}},[_vm._v(" "+_vm._s(connector.connectorId)+" ")]),_vm._v(" "+_vm._s(connector.errorCode)+" ")],1)}),1):_vm._e()]}},{key:"item.errorCode",fn:function(ref){
            var item = ref.item;
return [(item.details.connectors)?_c('div',{staticStyle:{"max-width":"200px"}},_vm._l((item.details.connectors),function(connector){return _c('v-chip',{key:connector.connectorId,staticClass:"mr-2 my-1"},[_c('v-avatar',{staticClass:"white black--text",attrs:{"left":""}},[_vm._v(" "+_vm._s(connector.connectorId)+" ")]),_vm._v(" "+_vm._s(connector.vendorErrorCode || "N/A")+" ")],1)}),1):_vm._e()]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }