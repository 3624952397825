






























































import coreApiClient from "@/services/apis/coreApiClient";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import Vue from "vue";
import Connectors from "./partials/charging-stations/Connectors.vue";
import userManager from "@/services/userManager";
import BlurredTenant from '@/components/BlurredTenant.vue';

export default Vue.extend({
    components: { Connectors, BlurredTenant },
    data() {
        return {
            showBlur: userManager.checkRole(["cpoAdmin", "cpoMonitor"], true) && !!userManager.getUserInfo().tenant?.setting?.expDate && userManager.getUserInfo().tenant.setting.expDate < new Date().getTime(),
            self: this,
            tableContainer: new DataContainer(),
            tableOptions: {
                attrs: {
                    "item-key": "_id",
                    "sort-by": "name",
                    "sort-desc": false,
                },
                content: {
                    name: "Unavailable/Error Station",
                    urlEnabled: true,
                    search: {
                        ext: {
                            hidden: true,
                        },
                    },
                    filters: {
                        filters: {
                            "uniqueId": {
                                attrs: {
                                    label: "Name",
                                },
                                rules: {
                                    match: {},
                                },
                            },
                            "details.connectors": {
                                attrs: {
                                    label: "Code",
                                },
                                rules: {
                                    elemMatch: {
                                        valueFilter: (data) => {
                                            if (data === 'N/A') {
                                                return {
                                                    "vendorErrorCode": { "$in": [null, ""] }
                                                }
                                            }
                                            return {
                                                "vendorErrorCode": { '$regex': data }
                                            }
                                        }
                                    },
                                },
                            },
                            "lastMessageConnector.1.message": {
                                attrs: {
                                    label: "Message Connector 1",
                                },
                                rules: {
                                    match: {},
                                },
                            },
                            "lastMessageConnector.2.message": {
                                attrs: {
                                    label: "Message Connector 2",
                                },
                                rules: {
                                    match: {},
                                },
                            },
                        },

                    },
                    displayFields: {
                        uniqueId: {
                            text: "Name",
                            options: {
                                attrs: {
                                    class: "font-weight-bold",
                                },
                            },
                        },
                        connectors: {
                            text: "Status"
                        },
                        errors: {
                            text: "Error"
                        },
                        errorCode: {
                            text: "Code"
                        },
                        detail: {
                            text: "Detail",
                        },
                        "details.firmwareVersion": {
                            text: "Firmware Version"
                        },
                        "details.lastSeen": {
                            text: "Last Seen",
                            options: {
                                filter: "dateTime",
                                attrs: {
                                    style: "width: 200px"
                                },
                            },
                        }
                    },
                    multiSelectActionButtons: {
                        export: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor", "retailer"]) && {
                            content: {
                                text: "Export selected",
                                icon: "mdi-microsoft-excel",
                            },
                            on: {
                                xClick({ self }) {
                                    const dataTable = self.context();
                                    coreApiClient.download("chargingStations", `status/in-error/export`, {
                                        payload: JSON.stringify({
                                            filters: [
                                                {
                                                    key: "_id",
                                                    operator: "in",
                                                    value: dataTable.model.value.map((item) => item._id),
                                                },
                                            ],
                                            limit: -1,
                                            extraParams: {
                                                currentTimeZoneOffset: new Date().getTimezoneOffset() * -1 * 60 * 1000,
                                            },
                                        }),
                                    });
                                },
                            },
                        },
                    },
                    topActionButtons: {
                        export: userManager.checkRole(["systemAdmin", "admin", "cpoAdmin", "cpoMonitor", "retailer"]) && {
                            content: {
                                text: "Export all",
                                icon: "mdi-microsoft-excel",
                            },
                            on: {
                                xClick({ self }) {
                                    const dataTable = self.context();
                                    coreApiClient.download("chargingStations", `status/in-error/export`, dataTable.getFindAllOptions());
                                },
                            },
                        },
                    },
                    findAllOptionsFilter(options) {
                        return options;
                    },
                },
                ext: {
                    dataProvider: {
                        async findAll(options) {
                            const { items, count } = await coreApiClient.call(
                                "chargingStations",
                                "chargingStationStatusInError",
                                options
                            );
                            return [items, count];
                        },
                    },
                },
            },
        };
    },
    methods: {},
    created() {
    },
    destroyed() {
    },
});

