<template>
    <v-container fluid>
        <div class="blurred-section">
            <div v-if="showBlur" class="blur-overlay">
                <div class="message">
                    <v-card class="pa-4">
                        <v-card-title class="headline">Tenant expired </v-card-title>
                        <v-card-text>
                            Tenant is expired. Please renew the tenant to continue using the service.
                        </v-card-text>
                    </v-card>
                </div>
            </div>
            <div class="content">
                <slot></slot>
            </div>
        </div>
    </v-container>
</template>

<script>
export default {
    name: 'BlurredTenant',
    props: {
        showBlur: {
            type: Boolean,
            default: true,
        },
    },
};
</script>

<style scoped>
.blurred-section {
    position: relative;
    overflow: hidden;
}

.blur-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(255, 255, 255, 0);
    backdrop-filter: blur(3px);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999999999999999;
}

.message {
    z-index: 2;
}

.content {
    position: relative;
    z-index: 3;
    padding: 20px;
    background: white;
}
</style>